var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tech-req__title"},[_vm._v(" "+_vm._s(_vm.text.menu_threads[_vm.language])+" ")]),(_vm.showEdit)?_c('div',{staticClass:"tech-req__card tech-req__card_plus",staticStyle:{"margin-bottom":"15px"}},[(!_vm.disableAdd)?_c('div',{staticStyle:{"height":"100%","width":"100%"},on:{"click":_vm.addThread}},[_c('div',{staticClass:"block block__center"},[_c('Plus')],1),_c('div',{staticClass:"block block__center"},[_c('div',{staticClass:"tech-req__card_plus_text"},[_vm._v(" "+_vm._s(_vm.text.th_surface_add[_vm.language])+" ")])])]):_c('div',[_c('div',{staticClass:"block block__center block__column block__align-center"},[_c('div',{staticClass:"tech-req__card_plus_text"},[_vm._v(" "+_vm._s(_vm.text.r_surface_select[_vm.language])+" ")])])])]):_vm._e(),_vm._l((_vm.threads),function(thread,index){return _c('div',{key:`thread_${thread.id}`},[_c('div',{class:`tech-req__block_wrapper ${
        thread.id === _vm.activeId ? 'tech-req__card_active' : ''}`},[_c('div',{staticClass:"tech-req__block_container"},[_c('div',{staticClass:"block block__between block__align-center",staticStyle:{"margin-bottom":"20px"}},[_c('div',{staticClass:"block__flex block__align-bottom"},[_c('div',[_vm._v(_vm._s(_vm.text.r_surface_count[_vm.language]))]),_c('div',{staticClass:"tech-req__card_count"},[_vm._v(" "+_vm._s(thread.surfaces.length)+" ")])]),_c('div',{staticClass:"block__flex block__align-center"},[_c('Info'),(_vm.showEdit)?_c('Close',{staticClass:"component__margin-left",on:{"click":function($event){return _vm.deleteThread(index)}}}):_vm._e()],1)]),_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('div',[(_vm.showEdit && thread.edit)?_c('Select',{attrs:{"label":_vm.text.th_thread_type[_vm.language],"show-arrow":"","default-value":thread.data.type
              ? _vm.setValue(thread.data.type, _vm.types, _vm.language) : '',"values":_vm.setValues(_vm.types, _vm.language),"margin-bottom":0,"dropdown-props":{
              label: 'label',
              value: 'value',
            }},on:{"change":function($event){return _vm.setData($event.value, 'type', index)}}}):_vm._e(),((!_vm.showEdit || !thread.edit) && thread.data.type)?_c('div',[_c('div',{staticClass:"tech-req__block_subtitle"},[_vm._v(" "+_vm._s(_vm.text.th_thread_type[_vm.language])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.setValue(thread.data.type, _vm.types, _vm.language))+" ")])]):_vm._e()],1)]),(thread.data.type)?_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px"}},[(_vm.showEdit && thread.edit)?_c('Select',{attrs:{"show-arrow":"","label":_vm.text.th_number_of_starts[_vm.language],"values":_vm.setValues(_vm.threads_starts, _vm.language),"default-value":!thread.data.thread_start
              ? _vm.setDefaultValue(_vm.threads_starts, _vm.language)
              : _vm.setValue(thread.data.thread_start, _vm.threads_starts, _vm.language),"margin-bottom":0,"dropdown-props":{
              label: 'label',
              value: 'value',
            }},on:{"change":function($event){return _vm.setData($event.value, 'thread_start', index)}}}):_vm._e(),((!_vm.showEdit || !thread.edit) && thread.data.thread_start)?_c('div',[_c('div',{staticClass:"tech-req__block_subtitle"},[_vm._v(" "+_vm._s(_vm.text.th_number_of_starts[_vm.language])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.setValue(thread.data.thread_start, _vm.threads_starts, _vm.language))+" ")])]):_vm._e()],1),_c('div',{staticStyle:{"margin-bottom":"15px"}},[(_vm.showEdit && thread.edit)?_c('Select',{attrs:{"show-arrow":"","label":_vm.text.th_location_surface[_vm.language],"values":_vm.setValues(_vm.surface_types, _vm.language),"default-value":!thread.data.surface_type
              ? _vm.setDefaultValue(_vm.surface_types, _vm.language)
              : _vm.setValue(thread.data.surface_type, _vm.surface_types, _vm.language),"margin-bottom":0,"dropdown-props":{
              label: 'label',
              value: 'value',
            }},on:{"change":function($event){return _vm.setData($event.value, 'surface_type', index)}}}):_vm._e(),((!_vm.showEdit || !thread.edit) && thread.data.surface_type)?_c('div',[_c('div',{staticClass:"tech-req__block_subtitle"},[_vm._v(" "+_vm._s(_vm.text.th_location_surface[_vm.language])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.setValue(thread.data.surface_type, _vm.surface_types, _vm.language))+" ")])]):_vm._e()],1),_c('div',{staticStyle:{"margin-bottom":"15px"}},[(_vm.showEdit && thread.edit)?_c('Select',{attrs:{"label":_vm.text.th_direction_line[_vm.language],"show-arrow":"","values":_vm.setValues(_vm.thread_helix, _vm.language),"default-value":!thread.data.thread_helix
              ? _vm.setDefaultValue(_vm.thread_helix, _vm.language)
              : _vm.setValue(thread.data.thread_helix, _vm.thread_helix, _vm.language),"margin-bottom":0,"dropdown-props":{
              label: 'label',
              value: 'value',
            }},on:{"change":function($event){return _vm.setData($event.value, 'thread_helix', index)}}}):_vm._e(),((!_vm.showEdit || !thread.edit) && thread.data.thread_helix)?_c('div',[_c('div',{staticClass:"tech-req__block_subtitle"},[_vm._v(" "+_vm._s(_vm.text.th_direction_line[_vm.language])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.setValue(thread.data.thread_helix, _vm.thread_helix, _vm.language))+" ")])]):_vm._e()],1),_c('div',{staticStyle:{"margin-bottom":"15px"}},[(_vm.showEdit && thread.edit)?_c('Input',{attrs:{"type":"number","label":_vm.text.th_external_d[_vm.language],"default-value":thread.data.external_diameter
                ? thread.data.external_diameter : '',"sub-label":thread.data.surface_type
                && thread.data.surface_type === 1 ? 'D' : 'd'},on:{"change":function($event){return _vm.resetTimer(_vm.setData, $event, 'external_diameter', index)}}}):_vm._e(),((!_vm.showEdit || !thread.edit) && thread.data.external_diameter)?_c('div',[_c('div',{staticClass:"tech-req__block_subtitle",staticStyle:{"text-transform":"unset !important"}},[_vm._v(" "+_vm._s(_vm.text.th_external_d[_vm.language])+" ("+_vm._s(thread.data.surface_type && thread.data.surface_type === 1 ? 'D' : 'd')+") ")]),_c('div',[_vm._v(" "+_vm._s(thread.data.external_diameter ? thread.data.external_diameter : '')+" ")])]):_vm._e()],1),_c('div',{staticStyle:{"margin-bottom":"15px"}},[(_vm.showEdit && thread.edit)?_c('Input',{attrs:{"type":"number","label":_vm.text.th_thread_pitch[_vm.language],"default-value":thread.data.pitch
                ? thread.data.pitch : '',"sub-label":"P"},on:{"change":function($event){return _vm.resetTimer(_vm.setData, $event, 'pitch', index)}}}):_vm._e(),((!_vm.showEdit || !thread.edit) && thread.data.pitch)?_c('div',[_c('div',{staticClass:"tech-req__block_subtitle",staticStyle:{"text-transform":"unset !important"}},[_vm._v(" "+_vm._s(_vm.text.th_thread_pitch[_vm.language])+" (P) ")]),_c('div',[_vm._v(" "+_vm._s(thread.data.pitch ? thread.data.pitch : '')+" ")])]):_vm._e()],1),_c('div',{staticStyle:{"margin-bottom":"15px"}},[(_vm.showEdit && thread.edit)?_c('Input',{attrs:{"type":"number","label":_vm.text.th_thread_length[_vm.language],"default-value":thread.data.length
                ? thread.data.length : '',"sub-label":"L"},on:{"change":function($event){return _vm.resetTimer(_vm.setData, $event, 'length', index)}}}):_vm._e(),((!_vm.showEdit || !thread.edit) && thread.data.length)?_c('div',[_c('div',{staticClass:"tech-req__block_subtitle",staticStyle:{"text-transform":"unset !important"}},[_vm._v(" "+_vm._s(_vm.text.th_thread_length[_vm.language])+" (P) ")]),_c('div',[_vm._v(" "+_vm._s(thread.data.length ? thread.data.length : '')+" ")])]):_vm._e()],1)]):_vm._e()]),_c('div',{staticClass:"block block__right block__align-center",staticStyle:{"margin-top":"20px"}},[(thread.edit)?_c('Button',{attrs:{"type":"primary","size":"small","text":_vm.text.r_save[_vm.language]},on:{"click":function($event){return _vm.saveThread(index)}}}):_vm._e(),(!thread.edit)?_c('Button',{attrs:{"type":"primary","size":"small","text":_vm.text.sp_edit[_vm.language]},on:{"click":function($event){return _vm.editThread(index)}}}):_vm._e()],1)])])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }