<template>
  <div>
    <div class="tech-req__title">
      {{text.menu_threads[language]}}
    </div>
    <div
      v-if="showEdit"
      style="margin-bottom: 15px;"
      class="tech-req__card tech-req__card_plus" >
      <div v-if="!disableAdd" @click="addThread" style="height: 100%; width: 100%">
        <div class="block block__center">
          <Plus />
        </div>
        <div class="block block__center">
          <div class="tech-req__card_plus_text">
            {{ text.th_surface_add[language] }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="block block__center block__column block__align-center">
          <div class="tech-req__card_plus_text">
            {{ text.r_surface_select[language] }}
          </div>
        </div>
      </div>
    </div>
    <div v-for="(thread, index) in threads" v-bind:key="`thread_${thread.id}`">
      <div :class="`tech-req__block_wrapper ${
          thread.id === activeId ? 'tech-req__card_active' : ''}`">
        <div class="tech-req__block_container">
          <div class="block block__between block__align-center" style="margin-bottom: 20px;">
            <div class="block__flex block__align-bottom">
              <div>{{ text.r_surface_count[language] }}</div>
              <div class="tech-req__card_count">
                {{thread.surfaces.length}}
              </div>
            </div>
            <div class="block__flex block__align-center">
              <Info />
              <Close
                v-if="showEdit"
                @click="deleteThread(index)"
                class="component__margin-left" />
            </div>
          </div>
          <div>
            <div
              style="margin-bottom: 15px;">
              <div>
                <Select
                  v-if="showEdit && thread.edit"
                  :label="text.th_thread_type[language]"
                  show-arrow
                  :default-value="thread.data.type
                ? setValue(thread.data.type, types, language) : ''"
                  @change="setData($event.value, 'type', index)"
                  :values="setValues(types, language)"
                  :margin-bottom="0"
                  :dropdown-props="{
                label: 'label',
                value: 'value',
              }"/>
                <div v-if="(!showEdit || !thread.edit) && thread.data.type">
                  <div class="tech-req__block_subtitle">
                    {{text.th_thread_type[language]}}
                  </div>
                  <div>
                    {{setValue(thread.data.type, types, language)}}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="thread.data.type">
              <div style="margin-bottom: 15px;">
                <Select
                  v-if="showEdit && thread.edit"
                  show-arrow
                  @change="setData($event.value, 'thread_start', index)"
                  :label="text.th_number_of_starts[language]"
                  :values="setValues(threads_starts, language)"
                  :default-value="!thread.data.thread_start
                ? setDefaultValue(threads_starts, language)
                : setValue(thread.data.thread_start, threads_starts, language)"
                  :margin-bottom="0"
                  :dropdown-props="{
                label: 'label',
                value: 'value',
              }"/>
                <div v-if="(!showEdit || !thread.edit) && thread.data.thread_start">
                  <div class="tech-req__block_subtitle">
                    {{text.th_number_of_starts[language]}}
                  </div>
                  <div>
                    {{setValue(thread.data.thread_start, threads_starts, language)}}
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 15px;">
                <Select
                  v-if="showEdit && thread.edit"
                  show-arrow
                  :label="text.th_location_surface[language]"
                  @change="setData($event.value, 'surface_type', index)"
                  :values="setValues(surface_types, language)"
                  :default-value="!thread.data.surface_type
                ? setDefaultValue(surface_types, language)
                : setValue(thread.data.surface_type, surface_types, language)"
                  :margin-bottom="0"
                  :dropdown-props="{
                label: 'label',
                value: 'value',
              }"/>
                <div v-if="(!showEdit || !thread.edit) && thread.data.surface_type">
                  <div class="tech-req__block_subtitle">
                    {{text.th_location_surface[language]}}
                  </div>
                  <div>
                    {{setValue(thread.data.surface_type, surface_types, language)}}
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 15px;">
                <Select
                  v-if="showEdit && thread.edit"
                  :label="text.th_direction_line[language]"
                  show-arrow
                  @change="setData($event.value, 'thread_helix', index)"
                  :values="setValues(thread_helix, language)"
                  :default-value="!thread.data.thread_helix
                ? setDefaultValue(thread_helix, language)
                : setValue(thread.data.thread_helix, thread_helix, language)"
                  :margin-bottom="0"
                  :dropdown-props="{
                label: 'label',
                value: 'value',
              }"/>
                <div v-if="(!showEdit || !thread.edit) && thread.data.thread_helix">
                  <div class="tech-req__block_subtitle">
                    {{text.th_direction_line[language]}}
                  </div>
                  <div>
                    {{setValue(thread.data.thread_helix, thread_helix, language)}}
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 15px;">
                <Input
                  type="number"
                  v-if="showEdit && thread.edit"
                  @change="resetTimer(setData, $event, 'external_diameter', index)"
                  :label="text.th_external_d[language]"
                  :default-value="thread.data.external_diameter
                  ? thread.data.external_diameter : ''"
                  :sub-label="thread.data.surface_type
                  && thread.data.surface_type === 1 ? 'D' : 'd'" />
                <div v-if="(!showEdit || !thread.edit) && thread.data.external_diameter">
                  <div class="tech-req__block_subtitle" style="text-transform: unset !important">
                    {{text.th_external_d[language]}} ({{thread.data.surface_type
                  && thread.data.surface_type === 1 ? 'D' : 'd'}})
                  </div>
                  <div>
                    {{thread.data.external_diameter ? thread.data.external_diameter : ''}}
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 15px;">
                <Input
                  type="number"
                  v-if="showEdit && thread.edit"
                  @change="resetTimer(setData, $event, 'pitch', index)"
                  :label="text.th_thread_pitch[language]"
                  :default-value="thread.data.pitch
                  ? thread.data.pitch : ''"
                  sub-label="P" />
                <div v-if="(!showEdit || !thread.edit) && thread.data.pitch">
                  <div class="tech-req__block_subtitle" style="text-transform: unset !important">
                    {{text.th_thread_pitch[language]}} (P)
                  </div>
                  <div>
                    {{thread.data.pitch ? thread.data.pitch : ''}}
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 15px;">
                <Input
                  type="number"
                  v-if="showEdit && thread.edit"
                  @change="resetTimer(setData, $event, 'length', index)"
                  :label="text.th_thread_length[language]"
                  :default-value="thread.data.length
                  ? thread.data.length : ''"
                  sub-label="L" />
                <div v-if="(!showEdit || !thread.edit) && thread.data.length">
                  <div class="tech-req__block_subtitle" style="text-transform: unset !important">
                    {{text.th_thread_length[language]}} (P)
                  </div>
                  <div>
                    {{thread.data.length ? thread.data.length : ''}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="block block__right block__align-center" style="margin-top: 20px;">
            <Button
              v-if="thread.edit"
              type="primary"
              size="small"
              @click="saveThread(index)"
              :text="text.r_save[language]" />
            <Button
              @click="editThread(index)"
              type="primary"
              size="small"
              :text="text.sp_edit[language]"
              v-if="!thread.edit" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/techV2.json';
import Plus from '@/assets/pictures/tech/plus.svg';
import Button from '@/components/button/Button.vue';
import Select from '@/components/select/Select.vue';
import Input from '@/components/inputV2/Input.vue';
import { query } from '@/utilites/axios';

const Info = () => import('@/assets/pictures/tech/info.svg');
const Close = () => import('@/assets/pictures/tech/close.svg');

export default {
  name: 'Threads',
  data() {
    return {
      text,
      timer: null,
      disableAdd: false,
      activeClick: false,
      showEdit: true,
      activeId: null,
      threads: [],
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
      surface_types: [
        {
          id: 1,
          label: {
            ru: 'Внутренняя',
            en: 'Internal',
          },
        },
        {
          id: 2,
          label: {
            ru: 'Внешняя',
            en: 'External',
          },
        },
      ],
      tolerance_letters: [
        {
          id: 1,
          label: 'a',
        },
        {
          id: 1,
          label: 'b',
        },
        {
          id: 1,
          label: 'cd',
        },
        {
          id: 1,
          label: 'd',
        },
        {
          id: 1,
          label: 'e',
        },
        {
          id: 1,
          label: 'ef',
        },
        {
          id: 1,
          label: 'f',
        },
        {
          id: 1,
          label: 'fg',
        },
        {
          id: 1,
          label: 'g',
        },
        {
          id: 1,
          label: 'h',
        },
        {
          id: 1,
          label: 'js',
        },
        {
          id: 1,
          label: 'j',
        },
        {
          id: 1,
          label: 'k',
        },
        {
          id: 1,
          label: 'm',
        },
        {
          id: 1,
          label: 'p',
        },
      ],
      threads_starts: [
        {
          id: 1,
          label: {
            ru: 'Однозаходная',
            en: 'Single-start',
          },
        },
        {
          id: 2,
          label: {
            ru: 'Двухзаходная',
            en: 'Two-start',
          },
        },
        {
          id: 3,
          label: {
            ru: 'Трехзаходная',
            en: 'Three-start',
          },
        },
        {
          id: 4,
          label: {
            ru: 'Четырехзаходная',
            en: 'Four-start',
          },
        },
        {
          id: 5,
          label: {
            ru: 'Многозаходная',
            en: 'Multi-start',
          },
        },
      ],
      thread_helix: [
        {
          id: 1,
          label: {
            ru: 'Правая резьба',
            en: 'Right-hand thread',
          },
        },
        {
          id: 2,
          label: {
            ru: 'Левая резьба',
            en: 'Left-hand thread',
          },
        },
      ],
      types: [
        {
          id: 1,
          label: {
            ru: 'Метрическая резьба (M)',
            en: 'Metric thread (M)',
          },
        },
        {
          id: 2,
          label: {
            ru: 'Коническая резьба (MK)',
            en: 'Conical thread (MK)',
          },
        },
        {
          id: 3,
          label: {
            ru: 'Круглая резьба (E)',
            en: 'Round thread (E)',
          },
        },
        {
          id: 4,
          label: {
            ru: 'Трапецеидальная резьба (Tr)',
            en: 'Trapezoidal thread (Tr)',
          },
        },
        {
          id: 5,
          label: {
            ru: 'Упорная резьба (S)',
            en: 'Buttress thread (S)',
          },
        },
        {
          id: 6,
          label: {
            ru: 'Прямоугольная резьба',
            en: 'Square thread',
          },
        },
        {
          id: 7,
          label: {
            ru: 'Цилиндрическая резьба (G)',
            en: 'Parallel pipe thread (G)',
          },
        },
        {
          id: 8,
          label: {
            ru: 'Коническая трубная резьба (R)',
            en: 'Tapered Pipe Thread (R)',
          },
        },
      ],
    };
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
    partData: {
      type: Object,
    },
  },
  watch: {
    edit: function (val) {
      this.showEdit = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    startTimer(callback, ...args) {
      this.timer = setTimeout(() => {
        if (typeof callback === 'function') {
          callback(...args);
        }
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(callback, ...args) {
      this.stopTimer();
      this.startTimer(callback, ...args);
    },
    // добавлям резьбу
    addThread() {
      // если доступно
      if (!this.disableAdd) {
        // подсвечиваем рамку
        this.$store.commit('modelV2/setShowModelFrame', true);
        // включаем блокировку добавления
        this.disableAdd = true;
        // даем возможность нажимать на деталь
        this.activeClick = true;
        // устанавливаем активный id
        this.activeId = this.threads.length + 1;
        // создаем в начале массива новый объект
        this.threads.unshift({
          id: this.activeId,
          surfaces: [],
          edit: true,
          didntSavedData: false,
          type: null,
          data: {
            type: null,
            thread_start: 1,
            surface_type: 1,
            thread_helix: 1,
            external_diameter: null,
            pitch: null,
          },
        });
      }
    },
    // функция которая передается в стор, событие которое происходит при нажатии на поверхность
    surfaceClicked(name) {
      // если нажатие доступно
      if (this.activeClick) {
        // создаем массив поверхностей которые надо разукрасить
        const result = [];
        // если есть активный id мы работаем именно с активным id
        if (this.activeId || this.activeId === 0) {
          // находим индекс элемента в массиве
          const index = this.threads.findIndex((el) => el.id = this.activeId);
          // создаем копию массива
          const threads = this.threads.slice();
          // находим индекс поверхности
          const surfaceIndex = threads[index].surfaces.findIndex((el) => el === name);
          // если поверхности нет, то добавляем
          if (surfaceIndex < 0) {
            if (threads[index] && threads[index].surfaces) {
              threads[index].surfaces.push(name);
            }
            // если есть то удаляем
          } else {
            threads[index].surfaces.splice(surfaceIndex, 1);
          }
          // закидываем в массив поверхность и цвет
          result.push({
            surfaces: threads[index].surfaces,
            color: this.clickColor,
          });
          // обновляем массив
          this.threads = threads;
        }
        // разукрашиваем поверхности
        this.$store.state.modelV2.decorateSurfaces(result);
      }
    },
    // удаляем элемент
    deleteThread(index) {
      // разблокируем возможность добавления
      this.disableAdd = false;
      // выключаем возможность нажимать на модель
      this.activeClick = false;
      // выключаем подствку обводки
      this.$store.commit('modelV2/setShowModelFrame', false);
      // очищаем раскраску поверхностей
      this.$store.state.modelV2.clearSurfaces();
      // удаляем элемент
      this.threads.splice(index, 1);
    },
    // меняем статус редактируемого элемента, все устанавливаются в false, а необходимый в обратное
    // значение
    setEdit(index) {
      const threads = this.threads.map((el, i) => {
        const thread = el;
        if (i === index) {
          thread.edit = !thread.edit;
        } else {
          thread.edit = false;
        }
        return thread;
      });
      this.threads = threads;
    },
    // сохраняем элемент
    saveThread(index) {
      // разрешаем добавлять
      this.disableAdd = false;
      // выключаем возможность нажимать
      this.activeClick = false;
      // убираем рамку
      this.$store.commit('modelV2/setShowModelFrame', false);
      // убираем активный id
      this.activeId = null;
      // очищаем раскраску поверхностей
      this.$store.state.modelV2.clearSurfaces();
      // изменияем статус редактирования
      this.setEdit(index);
      this.saveThreads();
    },
    editThread(index) {
      // ищем есть ли несохраненные изменения
      // убираем активный id
      this.activeId = index + 1;
      this.activeClick = true;
      this.$store.commit('modelV2/setShowModelFrame', false);
      // изменияем статус редактирования
      this.setEdit(index);
    },
    setValues(values, lang) {
      const result = [];
      for (const val of values) {
        result.push({
          value: val.id,
          label: val.label[lang],
        });
      }
      return result;
    },
    setDefaultValue(values, lang) {
      return values[0].label[lang];
    },
    setValue(value, values, lang) {
      const index = values.findIndex((el) => el.id === +value);
      return values[index].label[lang];
    },
    setData(value, name, index) {
      const threads = this.threads.slice();
      threads[index].data[name] = value;
      this.threads = threads;
    },
    getThreads() {
      return new Promise((resolve) => {
        query('post', 'getTechDataByPartOrder', {
          threads: true,
          uuid: this.$route.params.uuid,
        }, {
          token: localStorage.getItem('token'),
        }).then((result) => {
          this.threads = result.threads.threads ? result.threads.threads : [];
          resolve();
        });
      });
    },
    saveThreads() {
      query('post', 'changeTechDataByPartOrder', {
        hash: this.partData.hash,
        time_stamp: this.partData.time_stamp,
        file_name: this.partData.file_name,
        uuid: this.$route.params.uuid,
        threads: {
          threads: this.threads,
        },
      }, {
        token: localStorage.getItem('token'),
      });
    },
  },
  mounted() {
    this.showEdit = this.edit;
    this.getThreads();
    this.$store.commit('modelV2/setSurfaceClick', this.surfaceClicked);
  },
  beforeDestroy() {
    this.stopTimer();
  },
  components: {
    Plus,
    Info,
    Close,
    Button,
    Select,
    Input,
  },
};
</script>
